@import 'components/variables';

.home-hero {
  width: 100vw;
  height: calc(70vh - 60px);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
  @media (orientation: portrait) {
    height: auto;
    padding: 5rem 0;
  }
  .video-hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .placa {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #8345FF, $alpha: 0.4);
    z-index: -1;
  }
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70%;
    }
  }
}
.home-cases {
  padding: 5rem 0 0 0;
  .cases {
    padding: 5rem 0 0 0;
    .content-img-case {
      position: relative;
      width: 100%;
      padding-bottom: 80%;
      border-radius: 20px;
      background-color: $black;
      overflow: hidden;
      .img-case {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.8;
        transition: all 0.3s;
        &:hover {
          opacity: 1;
          transform: scale(1.2);
        }
      }
    }
  }
}
.home-other-brands {
  padding: 5rem 0;
  .content-slider-brands {
    width: 100%;
    margin: 0 auto;
    @media (min-width: 480px) {
      // width: 80%;
    }
    @media (min-width: 768px) {
      width: 80%;
    }
    .slider-other-brands {
      .slick-list {
        .slick-track {
          .slick-slide {
            .content-img-brand {
              background-color: $grey;
              position: relative;
              width: 100%;
              padding-bottom: 100%;
              border-radius: 15px;
              transition: all 0.3s;
              &:hover {
                transform: scale(1.1);
              }
              .img-brand {
                position: absolute;
                width: 70%;
                height: 55%;
                top: 50%;
                left: 50%;
                object-fit: contain;
                object-position: center;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
    .slick-next {
      width: 40px;
      transform: translate(100%, -80%);
      opacity: 0.6;
      transition: all 0.3s;
      &:hover {
        opacity: 1;
      }
    }
    .slick-prev {
      width: 40px;
      z-index: 1;
      transform: translate(-100%, -80%);
      opacity: 0.6;
      transition: all 0.3s;
      &:hover {
        opacity: 1;
      }
    }
    .slick-next:before,
    .slick-prev:before {
      display: none;
    }
  }
}