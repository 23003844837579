.home-hero {
  width: 100vw;
  height: calc(70vh - 60px);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative; }
  @media (orientation: portrait) {
    .home-hero {
      height: auto;
      padding: 5rem 0; } }
  .home-hero .video-hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }
    .home-hero .video-hero video {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center; }
  .home-hero .placa {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(131, 69, 255, 0.4);
    z-index: -1; }
  .home-hero .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .home-hero .container img {
      width: 70%; }

.home-cases {
  padding: 5rem 0 0 0; }
  .home-cases .cases {
    padding: 5rem 0 0 0; }
    .home-cases .cases .content-img-case {
      position: relative;
      width: 100%;
      padding-bottom: 80%;
      border-radius: 20px;
      background-color: #000000;
      overflow: hidden; }
      .home-cases .cases .content-img-case .img-case {
        position: absolute;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center;
           object-position: center;
        opacity: 0.8;
        transition: all 0.3s; }
        .home-cases .cases .content-img-case .img-case:hover {
          opacity: 1;
          transform: scale(1.2); }

.home-other-brands {
  padding: 5rem 0; }
  .home-other-brands .content-slider-brands {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .home-other-brands .content-slider-brands {
        width: 80%; } }
    .home-other-brands .content-slider-brands .slider-other-brands .slick-list .slick-track .slick-slide .content-img-brand {
      background-color: #c4c4c4;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      border-radius: 15px;
      transition: all 0.3s; }
      .home-other-brands .content-slider-brands .slider-other-brands .slick-list .slick-track .slick-slide .content-img-brand:hover {
        transform: scale(1.1); }
      .home-other-brands .content-slider-brands .slider-other-brands .slick-list .slick-track .slick-slide .content-img-brand .img-brand {
        position: absolute;
        width: 70%;
        height: 55%;
        top: 50%;
        left: 50%;
        -o-object-fit: contain;
           object-fit: contain;
        -o-object-position: center;
           object-position: center;
        transform: translate(-50%, -50%); }
    .home-other-brands .content-slider-brands .slick-next {
      width: 40px;
      transform: translate(100%, -80%);
      opacity: 0.6;
      transition: all 0.3s; }
      .home-other-brands .content-slider-brands .slick-next:hover {
        opacity: 1; }
    .home-other-brands .content-slider-brands .slick-prev {
      width: 40px;
      z-index: 1;
      transform: translate(-100%, -80%);
      opacity: 0.6;
      transition: all 0.3s; }
      .home-other-brands .content-slider-brands .slick-prev:hover {
        opacity: 1; }
    .home-other-brands .content-slider-brands .slick-next:before,
    .home-other-brands .content-slider-brands .slick-prev:before {
      display: none; }
